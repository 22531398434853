@import "./app.scss";

$bg-mockup: "../img/mockups/shopify-partners-mockup.png";

.shopify-partners-container {
    background-image: url($bg-mockup);
    background-size: cover;
    aspect-ratio: 1 / 0.45;
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;

    .logo {
        position: absolute;
        top: 28%;
    }

    @include desktop {
        border-radius: 15px;
        height: 250px;
        transform: translate(40px, 40px);

        .logo {
            left: 25px;
            height: 55px;
        }
    }

    @include tablet {
        border-radius: 15px;
        height: 200px;

        .logo {
            left: 20px;
            height: 48px;
        }
    }

    @include mobile {
        border-radius: 5px;
        height: 140px;

        .logo {
            bottom: 28%;
            left: 12px;
            height: 28px;
        }
    }
}
