@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/RobotoMono-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 700;
    font-style: normal;
}

/* Estilos individuales por peso y estilo */
@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/static/RobotoMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../fonts/Titilium/TitilliumWeb-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}