@import "./app.scss";

#header-section {
    position: fixed;
    top: 0;
    left: 0;
    text-align: -webkit-center;
    // padding-bottom: 50px;
    background: {
        image: url("../img/bg/bg-header.jpg");
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    z-index: 1;
    height: 100vh;
    width: 100%;
    // height: fit-content;
    overflow-y: auto;

    .mockups {
        position: relative;
        margin-bottom: 80px;
        z-index: 10;
        width: fit-content;


    }

    h1 {
        font-weight: 800;
        letter-spacing: 2.2px;
        z-index: 1;
        justify-self: center;
        text-transform: uppercase;
    }

    @include desktop {
        h1 {
            margin-bottom: 40px;
            font-size: 5.5rem;
            line-height: 6rem;
            max-width: 80%;
        }

        h3 {
            text-align: center;
            width: 60%;
            margin-bottom: 40px;
        }
    }

    @include tablet {
        h1 {
            margin-bottom: 40px;
            font-size: 5rem;
            line-height: 5rem;
        }

        h3 {
            width: 80%;
        }
    }

    @include mobile {
        h1 {
            margin-bottom: 20px;
            max-width: 90%;
            font-size: 2.1rem;
            line-height: 2.4rem;
        }

        h3 {
            width: 85%;
            font-size: 18px;
            font-size: 90%;
            margin-top: 20px;
        }
    }
}

