.chilisites-styled-button {
    border-radius: 5px; // Bordes redondeados
    padding: 30px 100px;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    transition: scale .2s ease;
    margin-top: 30px;
    margin-bottom: 20px;

    &:hover {
        scale: 1.2;
    }
}
