@import "./app.scss";

nav.navbar {
    padding: 40px 0;

    @include mobile {
        padding: 25px 0;
    }

    .container {
        @include tablet {
            padding: 0;
        }

        @include mobile {
            padding: 0 5%;
        }
    }

    img.logo {
        height: 80px;

        @include mobile {
            height: 65px;
        }

        cursor: pointer;
        transition: transform 0.6s ease-in-out;

        &:hover {
            transform: rotate(360deg);
        }

        &:not(:hover) {
            transform: rotate(0deg);
        }
    }

    // .collapse.navbar-collapse,
    // .collapse.navbar-collapse.show {
    //     transform: translateX(5%);
    // }

    ul {
        gap: 8rem;

        li {
            transition: transform 0.2s ease;
            &:hover {
                transform: scale(1.2);
            }
        }
    }

    button.navbar-toggler {
        height: 40px !important;
        padding: 12px !important;
        transition: transform 0.1s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    .navbar-toggler {
        border: none;

        &:focus {
            box-shadow: none;
        }

        .navbar-toggler-icon {
            background-image: none;
            position: relative;
            width: 30px;
            height: 3px;
            display: block;
            border: none;
            background-color: $color-purple;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 30px;
                height: 3px;
                background-color: black;
                transition: transform 0.3s ease;
            }

            &::before {
                top: -8px;
                background-color: $color-purple;
            }

            &::after {
                top: 8px;
                background-color: $color-purple;
            }
        }

        &:hover .navbar-toggler-icon {
            background-color: $color-purple;
        }
    }

    @include mobile {
        ul {
            gap: 15px;
        }

        .navbar-toggler-icon {
            background-color: $color-purple;
        }
    }

    @include tablet {
        ul {
            gap: 15px;
        }

        .navbar-toggler-icon {
            background-color: $color-purple;
        }
    }
}
