article.window-container {
    width: 90vw;
    max-width: 1300px;
    aspect-ratio: 5;
    border-radius: 8px;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    img.loading-frame {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    video.video {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
}
