@import "./app.scss";

.contact-button {
    font-family: sans-serif;
    font-size: 22px;
    font-weight: bold;
    border: 1px solid black;
    border-radius: 8px;
    padding: 6px 26px;

    /* Fondo con degradado de izquierda a derecha */
    background: linear-gradient(135deg, $color-orange 15%, $color-pink 60%, $color-white 112%);

    /* Sombra proyectada hacia abajo y a la derecha */
    box-shadow: 5px 5px 0px 0px #000000;

    /* Texto de color negro */
    color: #000000;
    cursor: pointer;

    /* Transición para efecto de hover */
    transition: all .05s ease-in-out;
}

/* Efecto hover */
.contact-button:hover {
    transform: translate(-1px, -1px);  /* Mueve el botón ligeramente hacia arriba */
    box-shadow: 7px 7px 0px 0px #000000;
}