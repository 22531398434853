/* Media queries */
@mixin mobile {
    @media (width <= 575px) {
        @content;
    }
}

@mixin tablet {
    @media (576px < width < 992px) {
        @content;
    }
}

@mixin desktop {
    @media (1050px <= width) {
        @content;
    }
}