@import "./app.scss";

#services-section {
    background-color: $color-white;
    padding: 80px 0 100px 0;
    position: relative;
    z-index: 10;

    @include mobile {
        padding-bottom: 60px;
    }


    .services-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        max-width: 1000px;
        margin: auto;

        justify-content: center;

        @media (width < 500px) {
            padding: 0 !important;
            text-align: center;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }

        .col-12 {
            justify-items: center;
        }

        
    }
}
