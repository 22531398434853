@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap");

:root {
    --bg: #000000;
    --clr-1: #00c2ff;
    --clr-2: #33ff8c;
    --clr-3: #ffc640;
    --clr-4: #e54cff;
    --blur: 1rem;
    --fs: clamp(3rem, 8vw, 7rem);
    --ls: clamp(-1.75px, -0.25vw,
            -3.5px);
}



.aurora-wrapper {
    text-align: center;
    position: relative;
    text-align: -webkit-center;
}


.aurora-title {
    color: transparent !important;
    font-size: var(--fs);
    font-weight: 800;
    letter-spacing: var(--ls);
    position: relative;
    margin: 0;
    color: transparent; /* Hace el texto transparente */
    background: linear-gradient(to right, var(--clr-1), var(--clr-2), var(--clr-3), var(--clr-4));
    -webkit-background-clip: text;
    background-clip: text;
    /* overflow: hidden; */
}

.aurora {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    z-index: -1;

}

.aurora__item {
    position: absolute;
    width: 50vw;
    height: 50vw;
    background-color: var(--clr-1);
    border-radius: 50%;
    filter: blur(var(--blur));
    mix-blend-mode: overlay;

}

.aurora__item:nth-of-type(1) {
    top: -50%;
    left: -30%;
    background-color: var(--clr-1);
    animation: aurora-border 6s ease-in-out infinite,
                aurora-1 5s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(2) {
    right: -30%;
    top: -30%;
    background-color: var(--clr-2);
    animation: aurora-border 6s ease-in-out infinite,
                aurora-2 5s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(3) {
    left: 20%;
    bottom: -40%;
    background-color: var(--clr-3);
    animation: aurora-border 6s ease-in-out infinite,
                aurora-3 3s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(4) {
    right: 10%;
    bottom: 10%;
    background-color: var(--clr-4);
    animation: aurora-border 6s ease-in-out infinite,
                aurora-4 13s ease-in-out infinite alternate;
}

/* Animación del efecto aurora */
@keyframes aurora-1 {
    0% {
        top: 0;
        right: 0;
    }

    50% {
        top: 100%;
        right: 75%;
    }

    75% {
        top: 100%;
        right: 25%;
    }

    100% {
        top: 0;
        right: 0;
    }
}

@keyframes aurora-2 {
    0% {
        top: -50%;
        left: 0%;
    }

    60% {
        top: 100%;
        left: 75%;
    }

    85% {
        top: 100%;
        left: 25%;
    }

    100% {
        top: -50%;
        left: 0%;
    }
}

@keyframes aurora-3 {
    0% {
        bottom: 0;
        left: 0;
    }

    40% {
        bottom: 100%;
        left: 75%;
    }

    65% {
        bottom: 40%;
        left: 50%;
    }

    100% {
        bottom: 0;
        left: 0;
    }
}

@keyframes aurora-4 {
    0% {
        bottom: -50%;
        right: 0;
    }

    50% {
        bottom: 0%;
        right: 40%;
    }

    90% {
        bottom: 50%;
        right: 25%;
    }

    100% {
        bottom: -50%;
        right: 0;
    }
}

@keyframes aurora-border {
    0% {
        border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
    }

    25% {
        border-radius: 47% 29% 39% 49% / 61% 19% 66% 26%;
    }

    50% {
        border-radius: 57% 23% 47% 72% / 63% 17% 66% 33%;
    }

    75% {
        border-radius: 28% 49% 29% 100% / 93% 20% 64% 25%;
    }

    100% {
        border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
    }
}

@keyframes gradient-shift {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}