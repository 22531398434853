@import "app.scss";

$color-white: #ffffff;
$color-gray-1: #f8f8f8;
$color-black: #212832;
$color-purple: #573ae7;
$light-purple: #e8e7ff;
$color-orange: #ef8e4a;
$color-pink: #dcb6f4;

// Colors
$colors: (
    // bg1: #dff9fb,
    // bg2: #ffffff,
    // color1: rgb(104, 109, 224),
    // color2: rgb(126, 214, 223),
    // color3: rgb(223, 249, 251),
    // color4: rgb(255, 121, 121),
    // color5: rgb(149, 175, 192),
    bg1: $color-pink,
    bg2: $color-white,
    color1: $color-purple,
    color2: $color-orange,
    color3: $light-purple,
    color4: $color-orange,
    color5: $color-gray-1
);

$animations: (
    color1: moveVertical 10s ease infinite,
    color2: moveInCircle 10s reverse infinite,
    color3: moveInCircle 10s linear infinite,
    color4: moveHorizontal 10s ease infinite,
    color5: moveInCircle 10s ease infinite,
);

// Other variables
$circle-size: 200%;
$blending: hard-light;

// 🎬 Mixin for Keyframes
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(moveInCircle) {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@include keyframes(moveVertical) {
    0% {
        transform: translateY(-50%);
    }

    50% {
        transform: translateY(50%);
    }

    100% {
        transform: translateY(-50%);
    }
}

@include keyframes(moveHorizontal) {
    0% {
        transform: translateX(-50%) translateY(-10%);
    }

    50% {
        transform: translateX(50%) translateY(10%);
    }

    100% {
        transform: translateX(-50%) translateY(-10%);
    }
}

#contact-section {
    position: relative;
    padding-bottom: 50px;
    padding-top: 180px;
    position: relative;
    z-index: 100;

    @include mobile {
        padding-top: 30px;
    }

    &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0)); 
        z-index: 100;
    }

    .gradient-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            40deg,
            map-get($colors, bg1),
            map-get($colors, bg2)
        );

        z-index: 90;
        overflow: hidden;

        // mask-image: linear-gradient(
        //     to bottom,
        //     $color-white 0%,
        //     rgba($color-white, 0) 30px
        // );
        // -webkit-mask-image: linear-gradient(
        //     to bottom,
        //     $color-white 0%,
        //     rgba($color-white, 0) 30px
        // );

        mask-image: linear-gradient(
            to bottom,
            // transparent 0%,
            $color-white,
            $color-white 240px
        );

        .gradients-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            filter: url(#goo) blur(40px);

            @each $key,
                $rgb
                    in (
                        1: map-get($colors, color1),
                        2: map-get($colors, color2),
                        3: map-get($colors, color3),
                        4: map-get($colors, color4),
                        5: map-get($colors, color5)
                    )
            {
                .g#{$key} {
                    position: absolute;
                    width: $circle-size;
                    height: $circle-size;
                    background: radial-gradient(
                        circle at center,
                        rgba($rgb, 0.8) 0%,
                        rgba($rgb, 0) 50%
                    );
                    mix-blend-mode: $blending;

                    animation: map-get($animations, color#{$key});
                }
            }
        }
    }

    .container {
        position: relative;
        z-index: 100;
        
        form {
            width: 70%;
            margin: auto;
            z-index: 100;

            h4 {
                color: $color-black;
                margin-bottom: 0;
                padding: 0px 0 20px 20px;
                width: fit-content;
                white-space: nowrap; // Asegura que el texto esté siempre en una línea
                // overflow: hidden;
                text-overflow: ellipsis; // Agrega puntos suspensivos si el texto es muy largo
            }

            input,
            textarea {
                border: none;
                padding: 20px 0 20px 20px;
                display: block;
                outline: none;
                width: 100%;
                background-color: rgba($color-gray-1, 0.3);
                margin-bottom: 15px;
                border-radius: 10px;

                &:focus-visible {
                    border: none;
                    outline: none;
                }

                &::placeholder,
                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder,
                &:-moz-placeholder {
                    color: map-get($colors, color-1);
                    opacity: 1;
                }
            }

            input[name="email"] {
                margin-left: 15px;
            }

            .checkbox {
                float: left;
                display: flex;
                gap: 10px;

                input[type="checkbox"] {
                    margin-bottom: 0;
                }
            }

            textarea {
                width: 100%;
                height: 130px;
            }

            .row-email-phone {
                display: flex;
                justify-content: flex-start;

                input[type="phone"] {
                    width: 50%;
                }
            }

            .content-center {
                width: 100%;
                justify-items: center;
                margin-top: 50px;
            }
        }

        .success-message {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 30px;
            font-size: 18px;
            font-weight: bold;
            color: map-get($colors, color-1);

            &.visible {
                opacity: 1;
                visibility: visible;
                color: rgba(87, 58, 231, 1);
                transition: color 0.5s ease-in;
            }

            &.hidden {
                opacity: 0;
                color: rgba(87, 58, 231, 0);
            }
        }

        @media (width < 500px) {
            form {
                width: 90%;
                margin-left: 15px !important;

                h4 {
                    font-size: 16px;
                }

                input,
                textarea {
                    &::placeholder,
                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &:-moz-placeholder {
                        font-size: 14px;
                    }
                }

                input[name="email"] {
                    margin-left: 0;
                }

                .row-email-phone {
                    display: block;

                    input[type="tel"] {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
