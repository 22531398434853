@import "./app.scss";

/* Variables */
$primary-color: var(--blue-dark);
$border-radius: 12px;
$padding-general: 10px;
$margin-auto: auto;

/* Estilos base */
.quote-card {
    background-color: white;
    border-radius: $border-radius;
    align-items: center;
    padding: $padding-general;
    height: 100%;
    margin: $margin-auto;
    width: 100%;

    img {
        object-fit: contain;
        padding: 5px;
        margin-right: 0;
    }
}

/* Contenedor de texto */
.quote-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    padding: 10px 10px 20px 10px;
    font-size: 1rem;
    text-align-last: left;
    width: 100%;

    p {
        text-align: center;
        text-align-last: center;
        margin-bottom: 0;
        color: $primary-color;
        width: 100%;
    }

    h3 {
        font-size: 1.3rem;
        margin-bottom: 0;
        color: $primary-color;
    }
}

/* Imagen dentro de la tarjeta */
.img-card-quote {
    width: 120px;
    height: auto;
    max-width: 100px;
    padding: 8px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    margin-right: 15px;
}

/* Media queries */
@mixin mobile {
    @media (width <= 575.98px) {
        @content;
    }
}

@mixin tablet {
    @media (576px <= width <= 991.98px) {
        @content;
    }
}

@mixin desktop {
    @media (992px <= width) {
        @content;
    }
}

@include mobile {
    .quote-card {
        width: 90vw;
    }

    .img-card-quote {
        width: 100%;
    }

    .quote-text {
        padding-left: 0;

        p {
            font-size: 0.8rem;
        }

        h3 {
            font-size: 1.2rem;
        }
    }

    .margin-x-5 {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@include tablet {
    .sm-px-0 {
        padding: 0 !important;
    }
}

@include desktop {
    .sm-px-0 {
        padding: 0 !important;
    }

    // .col-lg-6 {
    //     width: 100% !important;
    // }
}
