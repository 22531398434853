@import "app.scss";

.project-card {
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin: 0;
    max-width: 540px;

    img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 1 / 0.75;
        border-radius: 20px;
        transition: transform 0.3s ease-in-out, filter 0.2s ease-in;

        // &:hover {
        //     transform: translateY(-5px);
        // }
    }

    &:hover img {
        filter: brightness(40%);
    }

    h3 {
        font-size: 1.5rem;
        display: inline-block;
        padding: 10px;
        margin: 8px 0 0 10px;
        position: relative;
        color: $color-black;

        &::before {
            content: "{";
            position: absolute;
            bottom: 20px;
            left: -6px;
            color: $color-orange;
        }

        @media (width <= 770px) {
            font-size: 1.1rem;

            &::before {
                left: -2px;
            }
        }
    }

    h4 {
        font-size: 20px;
        color: $color-white;
        text-transform: uppercase;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        padding: 15px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &:hover {
        .project-link {
            opacity: 1;
        }

        h4 {
            opacity: 1;
        }
    }

    .project-card-footer {
        display: flex;
        position: absolute;
        bottom: 60px;
        left: 25px;
        width: 88%;
        justify-content: space-between;
    }
}

.project-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-weight: bold;
    color: $color-white;
    text-decoration: none;
    opacity: 0;
    transform-origin: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, font-size 0.3s ease, transform 0.3s ease;
    transform-origin: center;
    font-size: 30px;

    &:hover {
        transform: translate(-50%, -100%) scale(1.15);
    }

    @include tablet {
        font-size: 25px;

        &:hover {
            transform: translate(-50%, -100%) scale(1.15);
        }
    }

    @include mobile {
        font-size: 26px;

        &:hover {
            transform: translate(-50%, -100%) scale(1.2);
        }
    }
}
