@import "./app.scss";

.slider {
    background: transparent;
    height: fit-content;
    overflow: hidden;
    position: relative;
    width: 100vw;
    margin-bottom: 60px;

    &::after {
        right: 0;
        top: 0;

        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    // Para futuros efectos de gradientes
    // &::before,
    // &::after {
    //     background: linear-gradient(
    //         to right,
    //         rgba(138, 43, 226, 1) 0%,
    //         rgba(138, 43, 226, 0) 100%
    //     );
    //     content: "";
    //     height: 100%;
    //     position: absolute;
    //     width: 100px;
    //     z-index: 2;
    // }

    .slide-track {
        display: flex;
        gap: 12px;
        // animation: scroll 50s linear infinite;
        // -webkit-animation: scroll 50s linear infinite;

        .slide {
            margin: 0;
            border-radius: 10px;
            text-align-last: center;
            width: 400px;

            @include mobile {
                width: 180px;
            }
        }
    }

    // @keyframes scroll {
    //     0% {
    //         -webkit-transform: translateX(0);
    //         transform: translateX(0);
    //     }

    //     100% {
    //         -webkit-transform: translateX(calc(-400px * 8 - 12px * 7));
    //         transform: translateX(calc(-400px * 8 - 12px * 7));
    //     }
    // }

}
