@import './app.scss';

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    cursor: pointer;

    /* Contenedor del checkbox */
    input.custom-checkbox {
        appearance: none;
        border-radius: 10px;
        border: 2px solid black !important;
        margin-bottom: 0 !important;
        padding: 20px !important;

        /* Sombra que da el efecto 3D */
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 1);

        /* Degradado de fondo */
        background: linear-gradient(135deg, #ffffff 60%, #dcb6f4 100%);
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;
        cursor: pointer;

        /* Estilo del checkbox cuando está marcado */
        &:checked {
            border: none;
            background: linear-gradient(135deg, #ffffff 60%, #dcb6f4 100%);
        }
    }

    div.checkmark-container {
        position: absolute;
        left: 15px;
        scale: 2.8;

    }

    span.checkmark {
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        cursor: pointer;
    }

    /* Ajustar la alineación del label */
    label {
        margin-left: 8px;
        font-size: 90%;
        font-weight: bold;
        // color: $color-black;
        color: $color-black;
    }
}
