@import "../styles/app.scss";

#footer {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 70px 130px;
    position: relative;
    z-index: 100;

    .logo {
        img {
            margin-bottom: 20px;
            height: 80px;
            cursor: pointer;
            transition: scale .2s ease;

            &:hover {
                scale: 1.1;
            }
        }
    }

    .sections,
    .contact {
        a {
            display: block;
            text-decoration: none;
            color: white;
            padding-left: 8px;
            transition: color .2s ease, scale .2s ease;

            &:hover,
            &:hover svg {
                color: $color-pink;
                scale: 1.05;
            }
        }

        button {
            display: block;
            background: none;
            border: none;
            color: white;
            transition: color .2s ease, scale .2s ease;

            &:hover {
                color: $color-pink;
                scale: 1.05;
            }
        }

        &::before {
            content: "{";
            color: $color-orange;
        }
    }

    @media (width <= 768px) {
        display: block;
        padding-left: 60px;
        padding-right: 10%;

        & > * {
            margin-bottom: 20px;
        }

        .contact {
            a {
                margin-bottom: 8px;
            }
        }
    }
}
