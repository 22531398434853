.service-card {
    width: 400px;
    max-width: min(100%, 320px);
    cursor: pointer;
    aspect-ratio: 1/1;

    @media (500px < width < 1300px) {
        width: 100%;
    }

    .service-card-container {
        transform-style: preserve-3d;
        perspective: 1000px;
        height: 100%;

        .front,
        .back {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            text-align: center;
            font-size: 1.5rem;
            transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            color: #fff;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .front {
            transform: rotateY(0deg);
            transform-style: preserve-3d;
            backface-visibility: visible;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                backface-visibility: hidden;
            }

            .inner {
                transform: translateY(-50%) translateZ(60px) scale(0.94);
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 90%;
                    margin-bottom: 20px;
                }

                .icon-container {
                    height: fit-content;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    img {
                        width: 45%;
                    }
                }
            }
        }

        .back {
            transform: rotateY(180deg);
            transform-style: preserve-3d;
            background: linear-gradient(45deg, #cedce7 0%, #596a72 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .inner {
                padding: 0 15px !important;
                display: flex;
                align-items: center;

                @media (500px < width < 1300px) {
                    padding: 0 10px;
                }
            }
        }

        &:hover {
            .front {
                transform: rotateY(-180deg);
            }

            .back {
                transform: rotateY(0deg);
            }
        }

        .inner {
            transform: translateY(-50%) translateZ(60px) scale(0.94);
            top: 50%;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 2rem;
            box-sizing: border-box;
            outline: 1px solid transparent;
            perspective: inherit;
            z-index: 2;

            p {
                font-size: 95%;
                // margin-bottom: 2rem;
                margin-bottom: 0;
                white-space: normal;
                word-wrap: break-word;
                text-overflow: ellipsis;
                max-height: 100%;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                line-height: normal;
            }

            span {
                color: rgba(255, 255, 255, 0.7);
                font-family: "Montserrat";
                font-weight: 300;
            }
        }
    }
}
