@import "app.scss";

#projects-section {
    padding-top: 80px;
    padding-bottom: 50px;
    z-index: 100;
    position: relative;
    background-color: $color-white;

    @include mobile {
        padding-top: 0;
    }

    .container-fluid {
        justify-items: center;
        // overflow: hidden;
        // transition: max-height 2s ease;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(
            2,
            1fr
        ); // 2 columnas en pantallas grandes
        margin-right: 8vw;
        margin-left: 8vw;
        @media (width <= 1200px) {
            margin-right: 3vw;
            margin-left: 3vw;
        }
        justify-items: center;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        @media (width <= 767px) {
            grid-template-columns: 1fr; // 1 columna en dispositivos móviles
            margin-right: 2vw;
            margin-left: 2vw;
            gap: 25px;
        }
    }

    a.link-all-projects {
        margin-top: 40px;
        text-decoration: none;
        font-size: 1.8rem;
        display: inline-block;
        transition: transform 0.3s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (width < 500px) {
        a.link-all-projects {
            font-size: 1.2rem !important;
        }
    }

    h3 {
        text-align: center;
    }
}
