@import "./app.scss";

#quotes-about-section {
    background-image: url('../img/bg/bg-header.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 80px;
    padding-bottom: 190px;
    position: relative;
    z-index: 100;

    @include mobile {
        paddin-bottom: 154px;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 150px;
        background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); 
        z-index: 100;

        @include mobile {
            height: 100px;
        }
    }

    .container {
        padding-right: 0;
        padding-left: 0;
        
    }

    .content-center {
        justify-items: center;

        button {
            margin: auto;
        }
    }

    p {
        span {
            color: $color-orange;
        }
    }

    .about-us {
        @include mobile {
            padding: 0 5vw;
        }

        p {
            font-size: 1.75rem;
            @include desktop {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }
        @media (width < 500px) {
            & > p {
                font-size: 1.2rem;
                padding-left: 15px;
                width: 90%;
            }
        }
    }
}


