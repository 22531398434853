@import "app.scss";

.post-view-wrapper {
    background-color: $blogBgColor;
    flex-grow: 1;

    .spinner-container {
        display: flex;
        justify-content: center;
        scale: 2.5;
    }

    .post-container {
        table {
            width: fit-content;
            border-collapse: collapse;
            align-self: center;
            margin: 10px 0 30px 0;

            th,
            td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }

            .post-container th {
                background-color: #f2f2f2;
            }
        }

        .post-content {
            display: flex;
            flex-direction: column;
        }
    }
}
